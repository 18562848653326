import React from "react";
import { Nav, Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../assets/logo.svg";
import menu from "../assets/hamberger.svg";
import play from "../assets/video.svg";
import bild from "../assets/bild.svg";
import bookdu from "../assets/bookdu.svg";
import arisinfra from "../assets/arisinfra.svg";
import vananam from "../assets/vananam.svg";

import image2 from "../assets/image2.svg";
import image3 from "../assets/image3.svg";
import image4 from "../assets/image4.svg";
import image5 from "../assets/image5.svg";
import image6 from "../assets/image6.svg";
import image7 from "../assets/image7.svg";
import image8 from "../assets/image8.svg";
import ArisLogoH2 from "../assets/ArisLogoH2.svg";
import workwithus from "../assets/workwithus.svg";

const Footer = () => (
  <footer>
    <p>&copy; 2024 Your Company</p>
  </footer>
);

const ProjectDetails = [
  {
    title: "Bookdu",
    description:
      "I thank my lucky stars that I stumbled across you and your program. I have an enormous sense of satisfaction and peace. I feel a little more worthy.",
    image: bookdu,
    licontent: [
      "Branding",
      "Illustration",
      "Ecommerce Application Design",
      "Animation",
    ],
  },
  {
    title: "Arisinfra",
    description:
      "I thank my lucky stars that I stumbled across you and your program. I have an enormous sense of satisfaction and peace. I feel a little more worthy.",
    image: arisinfra,
    licontent: ["Branding", "Illustration", "Print Media", "Animation"],
  },
  {
    title: "Billd",
    description:
      "I thank my lucky stars that I stumbled across you and your program. I have an enormous sense of satisfaction and peace. I feel a little more worthy.",
    image: bild,
    licontent: [
      "Branding",
      "Illustration",
      "Website Design",
      "Application Design",
      "Animation",
    ],
  },
  {
    title: "Vananam Rewards",
    description:
      "I thank my lucky stars that I stumbled across you and your program. I have an enormous sense of satisfaction and peace. I feel a little more worthy.",
    image: vananam,
    licontent: ["Branding", "Illustration", "Website Design", "Animation"],
  },
];

function Home() {
  return (
    <div className="App">
      <Container fluid>
        <section className="banner-section">
          <Row className="logo">
            <Col xs={12} md={6}>
              {" "}
              <img src={logo} alt="Logo" />
            </Col>
            <Col xs={12} md={6} className="banner-section-menu">
              <img src={menu} alt="Menu" />
            </Col>
          </Row>
          <p className="banner-section-title">
            Where Ideas <span className="banner-section-title-span1">&</span>{" "}
            <span className="banner-section-title-span2">Creativity</span>{" "}
            <span className="banner-section-title-span3">SPROUT</span>
          </p>
          <div className="banner-section-subtitle">
            <p>Let us help you</p>
            <p className="banner-section-subtitle2">
              UX/UI Design Branding Illustration Web, App Development
            </p>
          </div>
        </section>

        <section className="video-section">
          <div>
            <img src={play} alt="Play" />
          </div>
        </section>

        <section className="our-project">
          <div>
            <p>
              Our <br /> highlight <br />
              project
            </p>
          </div>
        </section>

        <section className="project-highlight">
          {ProjectDetails.map((project, index) => (
            <ProjectHighLight
              title={project.title}
              description={project.description}
              licontent={project.licontent}
              image={project.image}
            />
          ))}
        </section>
        <section className="services">
          <p>Our Services</p>
          <p>
            <span>01</span> UI/UX Design
          </p>
          <p>
            <span>02</span>Illustration
          </p>
          <p>
            <span>03</span>Animation
          </p>
          <p>
            <span>04</span>Intraction
          </p>
          <p>
            <span>05</span>Print Media
          </p>
        </section>

        <section className="our-client">
          <p>Our Client</p>
          <Row style={{ padding: "5rem" }}>
            <Col xs={12} md={4} className="d-flex justify-content-center">
              <img src={image2} alt="Logo" />
            </Col>
            <Col xs={12} md={4} className="d-flex justify-content-center">
              <img src={image3} alt="Logo" />
            </Col>
            <Col xs={12} md={4} className="d-flex justify-content-center">
              <img src={image4} alt="Logo" />
            </Col>
          </Row>
          <Row style={{ padding: "5rem" }}>
            <Col xs={12} md={4} className="d-flex justify-content-center">
              <img src={image5} alt="Logo" />
            </Col>
            <Col xs={12} md={4} className="d-flex justify-content-center">
              <img src={image6} alt="Logo" />
            </Col>
            <Col xs={12} md={4} className="d-flex justify-content-center">
              <img src={image7} alt="Logo" />
            </Col>
          </Row>
          <Row style={{ padding: "5rem" }}>
            <Col xs={12} md={6} className="d-flex justify-content-center">
              <img src={image8} alt="Logo" />
            </Col>
            <Col xs={12} md={6} className="d-flex justify-content-center">
              <img src={ArisLogoH2} alt="Logo" />
            </Col>
          </Row>
        </section>

        <section className="work-with-us">
          <p>
            Start your work with us
            <img src={workwithus} alt="workwithus" />
          </p>
        </section>
      </Container>
    </div>
  );
}

function ProjectHighLight({ title, description, licontent, image }) {
  return (
    <Row>
      <Col xs={12} md={6} className="border-right">
        <div className="project-highlight-content">
          <Row>
            <Col>
              <p className="project-title">{title}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="project-description">{description}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <ul className="custom-ul">
                {licontent.map((service, index) => (
                  <li key={index} className="project-li">
                    {service}
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </div>
      </Col>
      <Col xs={12} md={6} style={{ textAlign: "center" }}>
        <img
          className="project-highlight-logo mx-auto"
          src={image}
          alt={title}
        />
      </Col>
    </Row>
  );
}

export default Home;
